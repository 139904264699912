<template>
  <div>
    <b-overlay
      show
      spinner-variant="primary"
      spinner-type="grow"
      spinner-large
      rounded="sm"
      style="top: 25em"
      v-if="mainOverlay"
    >
    </b-overlay>
    <!-- Providers List -->
    <b-tabs 
      pills
      v-model="tabIndex"
    >
      <b-tab v-for="provider in providers" :key="provider.idCompany">
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold"> {{ provider.companyName }} </span>
        </template>
        <b-overlay
          show
          rounded="sm"
          style="top: 25em"
          v-if="providerOverlay"
        >
        </b-overlay>
        <div v-if="devicesData[tabIndex]">
          <div v-if="devicesData[tabIndex].devices.length > 0">
            <b-card
              v-for="device in devicesData[tabIndex].devices"
              :key="device.idDevice"
              class="mb-3"
            >
              <!-- <b-overlay
                no-center
                :show="!device.power"
              >
                <template #overlay>
                  <b-icon
                    icon="BIconPower"
                    variant="danger"
                    scale="2"
                    shift-v="8"
                    shift-h="8"
                    class="position-absolute"
                    style="top: 0; right: 0"
                  />
                </template> -->
                <b-card-header class="mb-1">
                  <b-card-title> {{ (device.channel ? 'Videocámara ' : 'Raspberry Pi') + ' (' + device.ip + ', ' + device.port + ')' }} </b-card-title>
                  <b-card-text>
                    <b-badge
                      pill
                      :variant="device.power ? 'success' : 'danger'"
                      class="badge-glow mt-1"
                    >
                      {{ (device.power ? 'Encendido' : 'Apagado') + (!device.channel ? device.scaleConnection ? ' y conectado a la báscula' : ' y desconectado de la báscula' : '') }}
                    </b-badge>
                  </b-card-text>
                </b-card-header>
                <div class="d-flex ml-2">
                  <feather-icon
                    v-b-tooltip.hover
                    :title="'Última vez ' + (device.power ? 'apagado' : 'encendido') + ': ' + getStatusDates(device.reverse[0].creationDate / 1, 'reverseDate')"
                    icon="HelpCircleIcon"
                    size="18"
                    class="mr-50"
                  />
                  <h5>
                    Tiempo {{ device.power ? 'encendido' : 'apagado' }}: {{ getStatusDates(device.creationDate / 1, 'creationDate') }} horas
                  </h5>
                </div>
                <hr class="mb-2">
                <b-row align-h="center">
                  <b-col
                    lg="3"
                    sm="6"
                    v-if="!device.channel"
                  >
                    <statistic-card-horizontal
                      icon="CpuIcon"
                      color="primary"
                      :statistic="device.cpu ? Math.round(device.cpu )+ '%' : 'Sin datos'"
                      statistic-title="Uso de CPU"
                    />
                  </b-col>
                  <b-col
                    lg="3"
                    sm="6"
                    v-if="!device.channel"
                  >
                    <statistic-card-horizontal
                      icon="HardDriveIcon"
                      color="success"
                      :statistic="device.ram ? Math.round(device.ram) + ' MB' : 'Sin datos'"
                      statistic-title="Memoria RAM"
                    />
                  </b-col>
                  <b-col
                    lg="3"
                    sm="6"
                    v-if="!device.channel"
                  >
                    <statistic-card-horizontal
                      icon="ThermometerIcon"
                      color="danger"
                      :statistic="device.temp ? Math.round(device.temp) + ' °C' : 'Sin datos'"
                      statistic-title="Temperatura"
                    />
                  </b-col>
                  <b-col
                    lg="3"
                    sm="6"
                    v-if="device.channel"
                  >
                    <statistic-card-horizontal
                      icon="VideoIcon"
                      color="info"
                      :statistic="'Cámara ' + device.channel"
                      statistic-title="Canal"
                    />
                  </b-col>
                  <b-col
                    lg="3"
                    sm="6"
                  >
                    <statistic-card-horizontal
                      icon="AlertOctagonIcon"
                      color="warning"
                      statistic="0"
                      statistic-title="Problemas"
                    />
                  </b-col>
                </b-row>
              <!-- </b-overlay> -->
            </b-card>
          </div>
          <div v-else>
            <Empty
              :no-button="true"
              icon="LoaderIcon"
              title="No hay datos para mostrar"
              :content="'El proveedor \'' + provider.companyName + '\' no tiene dispositivos registrados.'"
            />
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>


<script>
import { BCard, BCardHeader, BCardTitle, BCardText, BCol, BRow, 
  BOverlay, BIcon, BIconPower, BTabs, BTab, BBadge, VBTooltip } from 'bootstrap-vue';
import { verifyToken, refreshToken } from '@/auth/utils';
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue';
import Empty from '../components/empty-page/Empty';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BOverlay,
    BRow,
    BCol,
    BIcon,
    BIconPower,
    BTabs,
    BTab,
    BBadge,
    Empty,
    VBTooltip,
    StatisticCardHorizontal
  },
  data() {
    return {
      // Data
      providers: [],
      devicesData: [],

      // Props
      mainOverlay: true,
      providerOverlay: true,
      tabIndex: 0
    }
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  created() {
    this.getCompanies();
  },
  methods: {
    getCompanies() {
      const userData = JSON.parse(localStorage.getItem("userData"));
      this.$axios.get(this.$services + 'providers/get_providers').then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.providers = res.data.data;
          if (this.$can('read', 'Calculator'))
            this.providers = this.providers.filter(provider => provider.idCompany == userData.idCompany);
          this.devicesData = Array(this.providers.length).fill(null);
          this.providers.forEach((provider, index) => {
            this.getDeviceStatus(provider.idCompany, index);
          });
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      }).finally(() => {
        this.mainOverlay = false;
      });
    },
    getDeviceStatus(idCompany, index) {
      this.providerOverlay = true;
      this.$axios.get(this.$services + 'iot/get_device_status', {
        params: {
          idCompany: idCompany
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.devicesData.splice(index, 1, res.data.data);
        } else
        throw 'Error al obtener datos.';
      }).catch(error => {
        if (typeof error != 'string') {
          this.$verifyToken(error.response.data.data);
        } this.showToast('danger', String(error));
      }).finally(() => {
        this.providerOverlay = false;
      });
    },
    getStatusDates(date, type) {
      switch (type) {
        case 'creationDate':
          return this.$moment().diff(this.$moment(date), 'hours');
      
        case 'reverseDate':
          return this.$moment(date).format('D/MM/YYYY - h:mm a');
      };
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notificación',
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
  }
}
</script>
